<template>
    <div class="policybox">
        <div class="" >
                <Row type="flex" justify="space-around" class="code-row-bg">
                    <Col span="6" push="7" class-name="zixing" @click.native="shouye()">首页</Col>
                    <Col span="6" push="2"   class-name="zixing">>政策法规</Col>
                    <Col span="6"  class-name="zi">>法规文件</Col>
              </Row>
            
          </div>
           <div class="jigoubox" >
               <div class="falv">
                   <div>
                        <img src="../../../assets/img/xinx/Combined Shape.png" class="tibiao" alt="">
                    <div class="ziti">法规文件</div>
                   </div>
                  <div class="brt">
                       <Input v-model="value" placeholder="请输入关键字" class="input" />
                        <Button type="primary" icon="ios-search" @click="search()">搜索</Button>
                  </div>
               </div>
                <div class="hengxian"></div>
                   <div v-for="(item,index) in wenjian" :key="index">
                      <div  class="di">
                          <div class="tit" @click="leix(item.newsMode,item.id)"> {{item.newsTitle}}</div>
                          <div class="time">{{item.createTime}}</div>
                      </div>
                   </div>    
                <!-- 分页 -->
                  <el-pagination class="fenye"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[15]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount">
                   </el-pagination>
            </div>
    </div>
    
</template>
<script>
import {queryPageNews} from "../../../woke/xinx"
import { formatDate } from '../../../commm/time'
export default {
    data(){
        return{
            value:"",
            wenjian:[],
            totalCount:null,//总数据
            currentPage:1,//初始页
            pageSize: Number(15) ,//每页显示条目数
            
        }
    },
    created(){
        this.queryPageNews()
    },
    methods:{
          //改变分页的每页的页数
     handleSizeChange(size) {
        //  console.log(size);
       this.pageSize = size
        this.queryPageNews()
    //    console.log(this.pageSize) //每页下拉显示数据
     },
     // 改变分页的当前页面
     handleCurrentChange(currentPage) {
         console.log(currentPage);
       this.currentPage = currentPage
        this.queryPageNews()
    //    console.log(this.currentPage) //点击第几页
     },
        shouye(){
            this.$router.push("/index")
        },
         //法规
        queryPageNews(){
             queryPageNews({
               curPage:this.currentPage,
               maxPage:this.pageSize,
               isStick:3,
               type:4
            }).then(res=>{
                // console.log(res,"法规");
                this.wenjian=res.data.data.data
                 this.totalCount =  res.data.data.total;
            })
        },
        search(){ 
          if(this.value==""){
              this.$Message.warning('请先输入内容');
          }else{
               this.$router.push({
                  path: "/search",
                  query: { value: this.value,currentPage:this.currentPage,pageSize:this.pageSize }
              })
            
          }
       this.$bus.$emit('Search',{ value: this.value, currentPage:this.currentPage, pageSize: this.pageSize})
      },
    
       leix(newsMode,id){
        //    console.log(newsTitle,createTime,newsResource,content,accessoryUrl,newsMode,accessoryName);
          if(newsMode==1){
            //   console.log(11);
               this.$router.push({
               name:"falv",
               params:{
                      id:id
                }
            });
          }else if(newsMode==2){
               window.open(content,"_blank")
          }
      },
    },  
    
    filters:{
       /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/ 
    formatDate(time) {
        var date = new Date(time);
        return formatDate(date, 'yyyy-MM-dd'); 
    },
    }
 
}
</script>
<style  scoped>
.policybox{
    min-height:100vh;
 
    margin-top:60px; 
 
    margin-bottom:30px; 
}
.code-row-bg{
    width: 350px;
    margin-left: 298px;
    margin-bottom: 20px;
}
.zixing{
    margin-top: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4D4D4D;
    line-height: 21px;
    cursor: pointer;
}
.zi{
     margin-top: 21px;
    font-size: 16px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #304C87;
    line-height: 21px;
}
.jigoubox{
    width: 100px;
    margin-left: 400px;
    margin-top: 30px;
    /* display: flex;
    justify-content: space-around */
}
.hengxian{
     margin-top: 10px;
     width:1040px;
     height:1px;
     background-color:#005CB0;
     overflow:hidden;
    margin-left: 20px;
    margin-bottom: 10px;
}
.ziti{
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #2C3B5B;
    line-height: 24px;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 10px;
}
.falv{
    width: 1040px;
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
}
.input{
    width: 200px;
    margin-left: 10px;
}
.di{
    width: 1040px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #2C3B5B;
    line-height: 21px;
    /* margin-bottom:451px; */
    margin-left: 20px;
}
.tit{
    width:850px;
    margin-bottom: 10px;
    cursor: pointer;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.tibiao{
    width: 10px;
    height: 16px;
    margin-top: -5px;
}
.fenye{
     width: 600px;
     margin-top: 50px;
     margin-left: 660px;
 }
 
</style>